import Bonus from "../pages/Bonus";
import LoginMUI from "../pages/LoginMUI";
import PromotionalMessageMUI from "../pages/PromotionalMessageMUI";
import PushNotificationMUI from "../pages/PushNotificationMUI";

export const route = [
  {
    id: "sms",
    path: "/",
    component: <PromotionalMessageMUI />,
  },
  {
    id: "push",
    path: "/push",
    component: <PushNotificationMUI />,
  },
  {
    id: "bonus",
    path: "/bonus",
    component: <Bonus />,
  },
];
export const fullPage = [
  {
    id: "login",
    path: "/login",
    component: <LoginMUI />,
  },
];
