import { LoadingButton } from "@mui/lab";
import { Stack, TextField, Typography, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { DataGrid, GridToolbarExport } from "@mui/x-data-grid";
import { useFormik } from "formik";
import { useState } from "react";
import { toast } from "react-toastify";
import * as yup from "yup";
import ContainerCard from "../components/ContainerCard";
import { pushNotification } from "../utilities/api";

const errorMessage = {
  titleReq: "Title is required",
  titleLength: "Title must be more than 3 characters",
  bodyReq: "Body is required",
  bodyLength: "Body must be more than 3 characters",
  userReq: "Please select user type",
  phoneReq: "Phone numbers are required",
  phoneInvalid: "Enter a valid phone number",
};

export const byteSize = (string) => {
  const encoder = new TextEncoder();
  return encoder.encode(string).length;
};

const removedNumbersCols = [
  {
    headerName: "Phone Number",
    field: "phoneNumber",
    sortable: false,
    minWidth: 150,
  },
  {
    headerName: "Status",
    field: "status",
    sortable: false,
  },
];


export default function PushNotificationMUI() {
  const [loading, setLoading] = useState(false);
  const [removedNumbers, setRemovedNumbers] = useState([]);

  const sendNotification = async ({ title, body, userType, phoneNumbers }) => {
    setLoading(true);
    setRemovedNumbers([]);

    const tempRemoved = [];

    const phoneNumbersModified = phoneNumbers
      .trim()
      .split("\n")
      ?.filter((phoneNumber) => {
        const isNumber = RegExp("^[0-9]{9}$").test(phoneNumber);
        if (!isNumber)
          tempRemoved.push({
            id: tempRemoved.length,
            phoneNumber,
            status: "Invalid",
          });
        return isNumber;
      })
      ?.map((phoneNumber) => phoneNumber);

    setRemovedNumbers(tempRemoved);

    if (phoneNumbersModified.length > 0) {
      const request = { message: { title, body }, phoneNumbers: phoneNumbersModified, userType };
      const response = await pushNotification(request);

      if (response?.status === 200) {
        resetForm();
        toast.success(`Finished. (Sent: ${response.data.sent}, Failed:${response.data.failed})`);
      } else if (response) {
        toast.warning(
          "Something went wrong:\n" + response.data.Error
        );
      }
    } else {
      toast.error("Please enter valid phone numbers");
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      body: "",
      userType: "",
      phoneNumbers: "",
    },
    validationSchema: yup.object({
      title: yup
        .string()
        .required(errorMessage.titleReq),
      body: yup
        .string()
        .required(errorMessage.bodyReq),
      userType: yup
        .string()
        .required(errorMessage.userReq)
        .min(1, errorMessage.userReq),
      phoneNumbers: yup
        .string()
        .required(errorMessage.phoneReq)
        .min(9, errorMessage.phoneInvalid),
    }),
    onSubmit: sendNotification,
  });

  const resetForm = () => {
    formik.resetForm();
  };
  const handleUserChange = (passed) => {
    formik.setValues({
      title: formik.values.title,
      body: formik.values.body,
      phoneNumbers: formik.values.phoneNumbers,
      userType: `${passed.target.value}`
    })
  };
  return (
    <Stack gap={3}>
      <ContainerCard>
        <Typography variant="h4" fontWeight={700} mb={2}>
          Push Notification
        </Typography>
        <Stack component="form" onSubmit={formik.handleSubmit} gap={2}>
          <Stack flexDirection={{ xs: "column", md: "row" }} gap={3}>
            <Stack flexDirection="column" minWidth={0.5}>
              <TextField
                id="title"
                name="title"
                margin="normal"
                maxRows={1}
                variant="outlined"
                placeholder="Title..."
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
              />
              <TextField
                id="body"
                name="body"
                multiline
                minRows={3}
                fullWidth
                variant="outlined"
                placeholder="Notification body..."
                value={formik.values.body}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.body && Boolean(formik.errors.body)}
                helperText={formik.touched.body && formik.errors.body}
              />
            </Stack>

            <TextField
              id="phoneNumbers"
              name="phoneNumbers"
              multiline
              maxRows={5}
              minRows={5}
              variant="outlined"
              placeholder="Phone Numbers"
              fullWidth
              margin="normal"
              value={formik.values.phoneNumbers}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.phoneNumbers &&
                Boolean(formik.errors.phoneNumbers)
              }
              helperText={
                formik.touched.phoneNumbers && formik.errors.phoneNumbers
              }
            />
          </Stack>

          <ToggleButtonGroup
            orientation="horizontal"
            color="info"
            exclusive
            id="userType"
            name="userType"
            value={formik.values.userType}
            onChange={handleUserChange}
          >
            <ToggleButton value="1" aria-label="label">
              Captains
            </ToggleButton>
            <ToggleButton value="2" aria-label="label">
              Customers
            </ToggleButton>
          </ToggleButtonGroup>
          {!formik.values.userType && <Typography> {'Please select user type'}</Typography>}
          {formik.values.userType && <Stack flexDirection="row" gap={2}>
            <LoadingButton variant="contained" type="submit" loading={loading}>
              Send
            </LoadingButton>
          </Stack>}
        </Stack>
      </ContainerCard>

      {!!removedNumbers.length && (
        <ContainerCard>
          <Typography variant="h5" fontWeight={500} mb={2}>
            Invalid Phone Numbers
          </Typography>
          <DataGrid
            rows={removedNumbers}
            columns={removedNumbersCols}
            autoHeight
            disableColumnFilter
            disableColumnMenu
            components={{ Toolbar: GridToolbarExport }}
            componentsProps={{
              toolbar: { csvOptions: { fileName: "invalid_phone_numbers" } },
            }}
          />
        </ContainerCard>
      )}
    </Stack>
  );
}
