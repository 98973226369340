import { toast } from "react-toastify";
import axiosInstance from "./axios";

export const login = async (body) => {
  try {
    const response = await axiosInstance.post("auth", body);
    return response;
  } catch (error) {}
  return null;
};

export const postMessage = async (body) => {
  try {
    const response = await axiosInstance.post("", body);
    return response;
  } catch (error) {
    if (error.response.status === 400 && error.response?.data?.Error) {
      toast.error("The provided phone numbers list is corrupted");
    }
  }
  return null;
};

export const postBonus = async (body) => {
  try {
    const response = await axiosInstance.post("bonus", body);
    return response.data;
  } catch (error) {}
  return null;
};

export const pushNotification = async (body) => {
  try {
    console.log('Sending', body);
    const response = await axiosInstance.post("push", body);
    console.log(response?.data);
    return response;
  } catch (error) {}
  return null;
};
