import { Stack, Typography } from "@mui/material";

export default function NoPageFound() {
  return (
    <Stack
      width="100vw"
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="h3">404 | Page not found</Typography>
    </Stack>
  );
}
