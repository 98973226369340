import { Routes, Route, Navigate } from "react-router-dom";
import Layout from "./components/Layout";
import NoPageFound from "./pages/NoPage";
import { fullPage, route } from "./utilities/routes";
import useAuth from "./hooks/useAuth";

function App() {
  const { token } = useAuth();
  return (
    <Routes>
      {fullPage?.map((route) => (
        <Route
          key={route.id}
          id={route.id}
          path={route.path}
          element={route.component}
        />
      ))}
      <Route path="/" element={<Layout />}>
        {route?.map((route) => (
          <Route
            key={route.id}
            id={route.id}
            path={route.path}
            element={token ? route.component : <Navigate to="/login" replace />}
          />
        ))}
      </Route>

      <Route path="*" element={<NoPageFound />} />
    </Routes>
  );
}

export default App;
