import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useFormik } from "formik";
import * as yup from "yup";
import ContainerCard from "../components/ContainerCard";
import { postBonus } from "../utilities/api";
import { toast } from "react-toastify";
import { byteSize } from "./PromotionalMessageMUI";

const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(yesterday.getDate() - 1);
const initValidTo = new Date(today);
initValidTo.setHours(initValidTo.getHours() + 12);

const messages = {
  required: "This field is required.",
  invalied: "Please provide a valid input.",
  invalidDate: "Please pick a valid date",
  validtoAfterStart: "End date must be after start date",
  smsLength: "Message is too long",
};
const yupSchema = yup.object({
  description: yup.string().required(messages.required),
  validfrom: yup
    .date()
    .nullable()
    .min(yesterday, messages.invalidDate)
    .typeError(messages.required)
    .required(messages.required),

  validto: yup
    .date()
    .nullable()
    .min(yup.ref("validfrom"), messages.validtoAfterStart)
    .typeError(messages.required)
    .required(messages.required),

  amount: yup
    .string()
    .required(messages.required)
    .test("positive", messages.invalied, (value) => value > 0),
  phoneNumbers: yup.string().required(messages.required),
  sms: yup
    .string()
    .required(messages.required)
    .test(
      "valid-length",
      messages.smsLength,
      (value) => byteSize(value) < 64000
    ),
});
export default function Bonus() {
  const [loading, setLoading] = useState(false);

  const submit = async (
    { description, amount, validfrom, validto, phoneNumbers, sms },
    { resetForm }
  ) => {
    setLoading(true);
    const phoneNumbersModified = phoneNumbers
      .trim()
      .split("\n")
      ?.filter((phoneNumber) => {
        return RegExp("^[0-9]{9}$").test(phoneNumber);
      })
      ?.map((phoneNumber) => 249 + phoneNumber);

    if (phoneNumbersModified.length > 0) {
      const body = {
        description,
        amount,
        validfrom,
        validto,
        phoneNumbers: phoneNumbersModified,
        message: sms,
      };

      const response = await postBonus(body);
      if (response?.requestId) {
        toast.success("Bonus sent successfully");
        resetForm();
      }
    } else {
      toast.error("Please enter valid phone numbers");
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      description: "",
      validfrom: today,
      validto: initValidTo,
      amount: "",
      phoneNumbers: "",
      sms: "",
    },
    validationSchema: yupSchema,
    onSubmit: submit,
  });

  return (
    <ContainerCard>
      <Typography variant="h4" color="text.main" fontWeight={700} mb={2}>
        Bonus
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Stack
          component="form"
          onSubmit={formik.handleSubmit}
          flexDirection="column"
          alignItems="flex-start"
          gap={2}
        >
          <Stack
            flexDirection={{ xs: "column", md: "row" }}
            gap={2}
            width="100%"
          >
            <TextField
              id="description"
              name="description"
              variant="outlined"
              size="small"
              placeholder="Bonus Description"
              fullWidth
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
            />
            <TextField
              id="amount"
              name="amount"
              variant="outlined"
              size="small"
              type="number"
              placeholder="Amount"
              fullWidth
              value={formik.values.amount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.amount && Boolean(formik.errors.amount)}
              helperText={formik.touched.amount && formik.errors.amount}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">SDG</InputAdornment>
                ),
              }}
            />
            <DateTimePicker
              value={formik.values.validfrom}
              onChange={(newValue) =>
                formik.setFieldValue("validfrom", newValue)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Valid From"
                  id="validfrom"
                  name="validfrom"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.validfrom && Boolean(formik.errors.validfrom)
                  }
                  helperText={
                    formik.touched.validfrom && formik.errors.validfrom
                  }
                />
              )}
            />
            <DateTimePicker
              value={formik.values.validto}
              onChange={(newValue) => formik.setFieldValue("validto", newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Valid To"
                  id="validto"
                  name="validto"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.validto && Boolean(formik.errors.validto)
                  }
                  helperText={formik.touched.validto && formik.errors.validto}
                />
              )}
            />
          </Stack>

          <Stack
            flexDirection={{ xs: "column", md: "row" }}
            gap={3}
            width="100%"
          >
            <TextField
              id="sms"
              name="sms"
              multiline
              minRows={5}
              variant="outlined"
              placeholder="Your message..."
              fullWidth
              value={formik.values.sms}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.sms && Boolean(formik.errors.sms)}
              helperText={formik.touched.sms && formik.errors.sms}
              InputProps={{
                endAdornment: (
                  <Typography
                    position="absolute"
                    bottom={5}
                    right={5}
                    variant="caption"
                    bgcolor="grey.50"
                    borderRadius={2}
                    px={1}
                  >
                    {byteSize(formik.values.sms)}/64000
                  </Typography>
                ),
              }}
            />

            <TextField
              id="phoneNumbers"
              name="phoneNumbers"
              multiline
              minRows={5}
              variant="outlined"
              placeholder="Phone Numbers"
              fullWidth
              value={formik.values.phoneNumbers}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.phoneNumbers &&
                Boolean(formik.errors.phoneNumbers)
              }
              helperText={
                formik.touched.phoneNumbers && formik.errors.phoneNumbers
              }
            />
          </Stack>

          <Stack flexDirection="row" gap={2} mt={2}>
            <LoadingButton
              loading={loading}
              variant="contained"
              color="secondary"
              type="submit"
            >
              Submit
            </LoadingButton>
            <Button variant="contained" color="grey" onClick={formik.resetForm}>
              Reset
            </Button>
          </Stack>
        </Stack>
      </LocalizationProvider>
    </ContainerCard>
  );
}
