import { useMemo, useState } from "react";
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import SidebarItem from "./SidebarItem";
import { menuItems } from "../../utilities/menuItems";

import useAuth from "../../hooks/useAuth";

import { VscMenu } from "react-icons/vsc";
import { FiLogOut } from "react-icons/fi";
import goLogo from "../../assets/AppLogoWhite.png";

export const drawerWidth = 240;

export default function Sidebar({ window }) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const { logout } = useAuth();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = useMemo(
    () => (
      <Box>
        <Box component="img" src={goLogo} width="60%" margin="5rem auto 1rem" />
        <List sx={{ color: { xs: "primary", sm: "white" } }}>
          {menuItems?.map((item) => (
            <SidebarItem
              key={item.id}
              name={item.name}
              icon={item.icon}
              path={item.path}
            />
          ))}

          <ListItem key="logout" disablePadding onClick={logout}>
            <ListItemButton>
              <ListItemIcon sx={{ fontSize: "1.5rem", color: "inherit" }}>
                {<FiLogOut />}
              </ListItemIcon>
              {<ListItemText primary={"Logout"} />}
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    ),
    [logout]
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Stack>
      <AppBar
        position="static"
        sx={{
          display: { xs: "block", sm: "none" },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <VscMenu />
          </IconButton>
          <Typography variant="h6" color="white" noWrap>
            GO
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
        }}
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              bgcolor: (theme) => theme.palette.primary.main,
              borderBottomRightRadius: 40,
              borderTopRightRadius: 40,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Stack>
  );
}
