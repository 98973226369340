import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { login } from "../utilities/api";
import { Box, Stack, TextField, Typography } from "@mui/material";
import src from "../assets/background.jpg";
import { LoadingButton } from "@mui/lab";

const errorMsg = {
  emailReq: "Email is required",
  passwordReq: "Password is required",
};

export default function LoginMUI() {
  const [loading, setLoading] = useState(false);
  const { login: saveToken } = useAuth();
  const navigate = useNavigate();

  const submitLogin = async ({ email, password }) => {
    setLoading(true);

    const body = { login: email, password };
    const response = await login(body);
    if (response) {
      const token = `Basic ${btoa(`${email}:${password}`)}`;
      saveToken(token);
      navigate("/");
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup.string().required(errorMsg.emailReq),
      password: yup.string().required(errorMsg.passwordReq),
    }),
    onSubmit: submitLogin,
  });

  return (
    <Stack
      bgcolor={{ xs: "white", sm: "inherit" }}
      minHeight="100vh"
      minWidth="100vw"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        component="form"
        onSubmit={formik.handleSubmit}
        bgcolor="white"
        flexDirection="row"
        width={{ xs: "100%", sm: "75%", md: "65%" }}
        borderRadius={{ xs: 0, sm: 5 }}
        boxShadow={{ xs: 0, sm: 3 }}
      >
        <Box
          component="img"
          src={src}
          width="40%"
          minHeight="100%"
          borderRadius={5}
          display={{ xs: "none", md: "inline" }}
          sx={{ objectFit: "cover" }}
        />
        <Stack
          width={{ xs: "100%", md: "60%" }}
          p={{ xs: 5, md: 7 }}
          boxSizing="border-box"
          spacing={3}
          alignItems="flex-start"
        >
          <Typography variant="h3" fontWeight={500} mb={{ xs: 1, md: 2 }}>
            Welcome back!
          </Typography>
          <TextField
            id="email"
            name="email"
            size="small"
            variant="outlined"
            placeholder="Email"
            fullWidth
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextField
            id="password"
            name="password"
            size="small"
            variant="outlined"
            placeholder="password"
            fullWidth
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <LoadingButton type="submit" loading={loading} variant="contained">
            Submit
          </LoadingButton>
        </Stack>
      </Stack>
    </Stack>
  );
}
