import { Link as RouterLink } from "react-router-dom";
import {
  Link,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

export default function SidebarItem({ name, icon, path }) {
  return (
    <Link
      component={RouterLink}
      to={path}
      underline="none"
      color={{ xs: "primary", sm: "white" }}
    >
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon sx={{ fontSize: "1.5rem", color: "inherit" }}>
            {icon}
          </ListItemIcon>
          <ListItemText primary={name} />
        </ListItemButton>
      </ListItem>
    </Link>
  );
}
