import * as MdOutline from "react-icons/md";
import { BiMoney } from "react-icons/bi";

export const menuItems = [
  {
    id: "sms",
    name: "Promotion Message",
    icon: <MdOutline.MdTextsms />,
    path: "/",
  },
  {
    id: "push",
    name: "Push Notifications",
    icon: <MdOutline.MdNotifications/>,
    path: "/push",
  },
  {
    id: "bonus",
    name: "Bonus",
    icon: <BiMoney />,
    path: "/bonus",
  },
];
