import { Stack } from "@mui/material";

export default function ContainerCard({ children }) {
  return (
    <Stack
      py={5}
      px={3}
      bgcolor="white"
      borderRadius="1.5rem"
      boxShadow="0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);"
      minWidth="min-content"
      width="100%"
    >
      {children}
    </Stack>
  );
}
