import axios from "axios";
// import { toast } from "react-toastify";

const axiosInstance = axios.create({
  baseURL: "https://zain.goteclabs.com:3000/",
});

// if (localStorage.getItem("TOKEN")) {
//   axiosInstance.defaults.headers.common[
//     "Authorization"
//   ] = `${localStorage.getItem("TOKEN")}`;
// }

// axiosInstance.interceptors.response.use(
//   function (response) {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data
//     return response;
//   },
//   function (error) {
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     if (error?.response && !error.response?.data?.Error) {
//       toast.error(
//         error.response?.data?.message || error?.message || "An error occurred"
//       );
//     } else if (!error?.response) {
//       toast.error(error?.message);
//     }
//     return Promise.reject(error);
//   }
// );

export default axiosInstance;
