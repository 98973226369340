import { LoadingButton } from "@mui/lab";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { DataGrid, GridToolbarExport } from "@mui/x-data-grid";
import { useFormik } from "formik";
import { useState } from "react";
import { toast } from "react-toastify";
import * as yup from "yup";
import ContainerCard from "../components/ContainerCard";
import { postMessage } from "../utilities/api";

const errorMessage = {
  smsReq: "Message is required",
  smsLength: "Message is too long",
  phoneReq: "Phone numbers are required",
  phoneInvalid: "Enter a valid phone number",
};

export const byteSize = (string) => {
  const encoder = new TextEncoder();
  return encoder.encode(string).length;
};

const removedNumbersCols = [
  {
    headerName: "Phone Number",
    field: "phoneNumber",
    sortable: false,
    minWidth: 150,
  },
  {
    headerName: "Status",
    field: "status",
    sortable: false,
  },
];

const columns = [
  {
    headerName: "#",
    field: "id",
    sortable: false,
  },
  {
    headerName: "Phone Number",
    field: "phoneNumber",
    sortable: false,
    minWidth: 150,
  },
  {
    headerName: "Status",
    field: "status2",
    sortable: false,
  },
  {
    headerName: "Reason",
    field: "status",
    sortable: false,
    flex: 1,
  },
];

export default function PromotionalMessageMUI() {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [removedNumbers, setRemovedNumbers] = useState([]);

  const sendMessage = async ({ sms, phoneNumbers }) => {
    setLoading(true);
    setTableData([]);
    setRemovedNumbers([]);

    const tempRemoved = [];

    const phoneNumbersModified = phoneNumbers
      .trim()
      .split("\n")
      ?.filter((phoneNumber) => {
        const isNumber = RegExp("^[0-9]{9}$").test(phoneNumber);
        if (!isNumber)
          tempRemoved.push({
            id: tempRemoved.length,
            phoneNumber,
            status: "Invalid",
          });
        return isNumber;
      })
      ?.map((phoneNumber) => 249 + phoneNumber);

    setRemovedNumbers(tempRemoved);

    if (phoneNumbersModified.length > 0) {
      const body = { message: sms, phoneNumbers: phoneNumbersModified };
      const response = await postMessage(body);

      if (response?.data?.unsuccessful?.length === 0) {
        resetForm();
        toast.success("Messages sent successfully");
      } else {
        toast.warning(
          "Some messages went through, check the table below for the failed attempts."
        );
        setTableData(
          response?.data?.unsuccessful?.map((item, index) => {
            return { ...item, id: index++, status2: "Failed" };
          })
        );
      }
    } else {
      toast.error("Please enter valid phone numbers");
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      sms: "",
      phoneNumbers: "",
    },
    validationSchema: yup.object({
      sms: yup
        .string()
        .required(errorMessage.smsReq)
        .test(
          "valid-length",
          errorMessage.smsLength,
          (value) => byteSize(value) < 64000
        ),
      phoneNumbers: yup
        .string()
        .required(errorMessage.phoneReq)
        .min(9, errorMessage.phoneInvalid),
    }),
    onSubmit: sendMessage,
  });

  const handleReset = () => {
    resetForm();
    setRemovedNumbers([]);
  };

  const resetForm = () => {
    formik.resetForm();
    setTableData([]);
  };

  return (
    <Stack gap={3}>
      <ContainerCard>
        <Typography variant="h4" fontWeight={700} mb={2}>
          Promotional Message
        </Typography>
        <Stack component="form" onSubmit={formik.handleSubmit} gap={2}>
          <Stack flexDirection={{ xs: "column", md: "row" }} gap={3}>
            <TextField
              id="sms"
              name="sms"
              multiline
              minRows={5}
              variant="outlined"
              placeholder="Your message..."
              fullWidth
              value={formik.values.sms}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.sms && Boolean(formik.errors.sms)}
              helperText={formik.touched.sms && formik.errors.sms}
              InputProps={{
                endAdornment: (
                  <Typography
                    position="absolute"
                    bottom={5}
                    right={5}
                    variant="caption"
                    bgcolor="grey.50"
                    borderRadius={2}
                    px={1}
                  >
                    {byteSize(formik.values.sms)}/64000
                  </Typography>
                ),
              }}
            />

            <TextField
              id="phoneNumbers"
              name="phoneNumbers"
              multiline
              maxRows={5}
              minRows={5}
              variant="outlined"
              placeholder="Phone Numbers"
              fullWidth
              value={formik.values.phoneNumbers}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.phoneNumbers &&
                Boolean(formik.errors.phoneNumbers)
              }
              helperText={
                formik.touched.phoneNumbers && formik.errors.phoneNumbers
              }
            />
          </Stack>

          <Stack flexDirection="row" gap={2}>
            <LoadingButton variant="contained" type="submit" loading={loading}>
              Submit
            </LoadingButton>
            <Button variant="contained" color="grey" onClick={handleReset}>
              Reset
            </Button>
          </Stack>
        </Stack>
      </ContainerCard>

      {!!tableData.length && (
        <ContainerCard>
          <Typography variant="h5" fontWeight={500} mb={2}>
            Unsuccessful Attempts
          </Typography>
          <DataGrid
            rows={tableData}
            columns={columns}
            autoHeight
            disableColumnFilter
            disableColumnMenu
            components={{ Toolbar: GridToolbarExport }}
            componentsProps={{
              toolbar: { csvOptions: { fileName: "unsuccessful_attempts" } },
            }}
          />
        </ContainerCard>
      )}
      {!!removedNumbers.length && (
        <ContainerCard>
          <Typography variant="h5" fontWeight={500} mb={2}>
            Invalid Phone Numbers
          </Typography>
          <DataGrid
            rows={removedNumbers}
            columns={removedNumbersCols}
            autoHeight
            disableColumnFilter
            disableColumnMenu
            components={{ Toolbar: GridToolbarExport }}
            componentsProps={{
              toolbar: { csvOptions: { fileName: "invalid_phone_numbers" } },
            }}
          />
        </ContainerCard>
      )}
    </Stack>
  );
}
